<template>
  <button
    :class="{
      active,
      'ui-fat-radio-item--error': error
    }"
    :title="title"
    :aria-checked="active"
    type="button"
    role="radio"
    class="btn ui-fat-radio-item"
  >
    <div
      v-if="tooltip"
      :title="tooltip"
      class="ui-fat-radio-item__container"
      v-b-tooltip.hover.html
    >
      <slot />
    </div>
    <div
      v-else
      class="ui-fat-radio-item__container"
    >
      <slot />
    </div>
  </button>
</template>

<script>
  /**
   * @module component - UiFatRadioItem
   * @param {string} [active=false]
   * @param {string} title
   */
  export default {
    name: 'UiFatRadioItem',
    props: {
      active: {
        type: Boolean,
        default: false
      },
      title: {
        type: String,
        required: true
      },
      error: {
        type: Boolean,
        default: false
      },
      tooltip: {
        type: String,
        required: false,
        default: null
      }
    }
  }
</script>

<style lang="scss" scoped>
.ui-fat-radio-item {
  --tw-text-opacity: 1;
  color: rgba(103, 106, 108, var(--tw-text-opacity));
  border: 1px solid $divider;
  min-height: 120px;
  transition: none;
  transition: box-shadow 200ms;
}
.ui-fat-radio-item.active {
  position: relative;
  border: 1px solid $info;
  background-color: $info;
  color: white;
}
.ui-fat-radio-item:disabled {
  cursor: not-allowed;
  filter: grayscale(1);
}
.ui-fat-radio-item:focus {
  box-shadow: none;
}
.ui-fat-radio-item:hover:not(:disabled), .ui-fat-radio-item:focus-visible:not(:disabled) {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
}
.ui-fat-radio-item:hover:not(.active):not(:disabled):not(.ui-fat-radio-item--error), .ui-fat-radio-item:focus-visible:not(.active):not(:disabled):not(.ui-fat-radio-item--error) {
  border: 1px solid #F9F9F9;
  background-color: #F9F9F9;
}
.ui-fat-radio-item:hover:focus:not(:disabled).active {
  box-shadow: none;
}
.ui-fat-radio-item:hover.active:not(:disabled), .ui-fat-radio-item:focus-visible.active:not(:disabled) {
  background-color: lighten($info, 5%);
}
.ui-fat-radio-item--error {
  --tw-border-opacity: 1;
  border-color: rgba(215, 40, 63, var(--tw-border-opacity));
}
@media only screen and (max-width: $breakpoint-tablet) {
  .ui-fat-radio-item {
    min-height: 80px;
  }
}
</style>
